import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable, NgZone } from '@angular/core';
import { loadStripe, Stripe } from '@stripe/stripe-js';

@Injectable({ providedIn: 'root' })
export class StripeService {
	private _stripe: Stripe | null = null;
	private _onLoad: BehaviorSubject<boolean> = new BehaviorSubject(false);

	constructor(private zone: NgZone) {
		this.initStripe();
	}

	// Initialisation de Stripe
	private async initStripe() {
		try {
			this._stripe = await loadStripe(environment.stripePublicKey);
			this._onLoad.next(true);
			console.log('Stripe chargé avec succès.');
		} catch (error) {
			console.error('Erreur lors du chargement de Stripe:', error);
			this._onLoad.next(false);
		}
	}

	get stripe(): Stripe | null {
		return this._stripe;
	}

	get onLoad(): Observable<boolean> {
		return this._onLoad.asObservable();
	}
}
